import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Link,
  Button,
  Text,
  Select,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon, InfoIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import { useUserAuth } from "../context/UserAuthProvider";
import EmailVerification from "./EmailVerification";
import {
  trimSpaces,
  isAlphaNumericOrSymbol,
  isValidEmail,
  isValidPhoneNumber,
} from "../utilities/utilities";
import {
  emptyFieldError,
  invalidEmailError,
  invalidPhoneNumberError,
  invalidUsernameError,
  invalidPasswordError,
  emailExistsError,
  phoneNumberNameExistsError,
  usernameExistsError,
  passwordMismatchError,
  formIncompleteMessage,
  accountCreationFailed,
  accountCreated,
} from "../utilities/messages";
import { duration } from "moment";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_DUPLICATE_CHECK = "/duplicate_check";

export default function SignUp() {
  const navigate = useNavigate();
  const toast = useToast();
  const { t, i18n } = useTranslation("sign_up");
  const { meritoSignedIn, firebaseUserSignUp } = useUserAuth();

  const fieldToValidation = {
    validate: {
      email: isValidEmail,
      phone_number: isValidPhoneNumber,
      username: isAlphaNumericOrSymbol,
    },
    invalid: {
      email: invalidEmailError,
      phone_number: invalidPhoneNumberError,
      username: invalidUsernameError,
    },
    duplicate: {
      email: emailExistsError,
      phone_number: phoneNumberNameExistsError,
      username: usernameExistsError,
    },
  };

  const [pageTitle, setPageTitle] = useState("title_sign_up");
  const [accountCreated, setAccountCreated] = useState(false);
  const [signUpForm, setSignUpForm] = useState({
    email: null,
    phone_number: null,
    first_name: null,
    last_name: null,
    username: null,
    password: null,
    user_image: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState({ ...signUpForm });

  const checkSignInStatus = () => {
    // Move to the portal page if the user is signed in
    if (meritoSignedIn) {
      navigate("/portal");
    }
  };

  const handleInputChange = (event) => {
    checkSignInStatus();
    event.preventDefault();
    const { name, value, files } = event.target;

    setSignUpForm({
      ...signUpForm,
      [name]: name === "user_image" ? files[0] : value,
    });
  };

  const handleInputValidation = async (event) => {
    checkSignInStatus();
    event.preventDefault();
    const { name, value, files } = event.target;
    let input;

    if (["email", "phone_number", "username"].includes(name)) {
      input = trimSpaces(value);
      let isDuplicate = false;
      // Update the value in the form
      setSignUpForm({ ...signUpForm, [name]: input });

      // Check if the input is valid
      const isValid = fieldToValidation.validate[name](input);

      // Check if the email/phone number/username already exists
      if (input) {
        console.log(`Calling Merito API: ${ENDPOINT_DUPLICATE_CHECK}`);
        const response = await axios.get(
          `${MERITO_API_HOST}${ENDPOINT_DUPLICATE_CHECK}?${name}=${encodeURIComponent(
            input
          )}`
        );
        isDuplicate = response.data.content[name];
      }

      // Set error message based on the input duplication status and empty/null status
      setFormErrorMessages({
        ...formErrorMessages,
        [name]:
          input === "" || input === null
            ? emptyFieldError[i18n.language]
            : !isValid
            ? fieldToValidation.invalid[name][i18n.language]
            : isDuplicate
            ? fieldToValidation.duplicate[name][i18n.language]
            : null,
      });
      return;
    } else if (name === "password") {
      input = trimSpaces(value);
      // Update the value in the form
      setSignUpForm({ ...signUpForm, [name]: input });

      // Check if the input is valid
      const isValid = isAlphaNumericOrSymbol(input);

      // Set error message if the input is not valid
      setFormErrorMessages({
        ...formErrorMessages,
        [name]:
          input === "" || input === null
            ? emptyFieldError[i18n.language]
            : !isValid
            ? invalidPasswordError[i18n.language]
            : null,
      });
      return;
    } else if (["first_name", "last_name"].includes(name)) {
      input = trimSpaces(value);
    } else if (name === "user_image") {
      input = files[0];
    }

    // Update the value in the form
    setSignUpForm({ ...signUpForm, [name]: input });

    // Check if the input is empty/null
    setFormErrorMessages({
      ...formErrorMessages,
      [name]:
        input === "" || input === null ? emptyFieldError[i18n.language] : null,
    });
  };

  const checkFieldStatus = () => {
    // Copy the current error messages
    const newErrorStatus = { ...formErrorMessages };

    Object.entries(newErrorStatus).forEach(([field, message]) => {
      const value = signUpForm[field];

      // Skip user_image
      // TODO: Enable user_image field
      if (field === "user_image") return;

      // If the field already has an error message, skip the check
      if (message !== null) return;

      // Check if the value is null and update the error message
      newErrorStatus[field] =
        value === null ? emptyFieldError[i18n.language] : null;
    });

    // Set the updated error messages after the iteration
    setFormErrorMessages(newErrorStatus);

    // Return true if there are still error messages
    const hasIncompleteFields = Object.values(newErrorStatus).some(
      (message) => message !== null
    );
    return hasIncompleteFields;
  };

  const handleSignUp = async (event) => {
    checkSignInStatus();
    event.preventDefault();

    // First check if any fields still have error messages or are empty/null
    const hasIncompleteFields = checkFieldStatus();
    if (hasIncompleteFields) {
      toast({
        status: "error",
        isClosable: true,
        title: "Error",
        description: formIncompleteMessage[i18n.language],
      });
      return;
    }

    // Sign up with email and password, and send a verification email
    const _ = await firebaseUserSignUp(signUpForm.email, signUpForm.password);

    // Move to the email verification step
    setAccountCreated(true);
  };

  useEffect(() => {
    const title = accountCreated ? "title_verification" : "title_sign_up";
    setPageTitle(title);
  }, [accountCreated]);

  useEffect(() => {
    checkSignInStatus();
  }, [meritoSignedIn]);

  return (
    <Flex align="center" justify="center">
      <Stack
        className={i18n.language === "jp" ? "jp" : "en"}
        spacing={8}
        mx="auto"
        maxW="2xl"
        pt={12}
        pb={16}
        px={6}
      >
        <Stack align="center">
          <Text
            fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}
            textAlign="center"
            color="Neutral.100"
          >
            {t(pageTitle)}
          </Text>
        </Stack>

        {/* Form */}
        <Box borderRadius="lg" bg="Neutral.0" boxShadow="xl" p={9}>
          {accountCreated ? (
            // Email Verification Form
            <EmailVerification
              signUpForm={signUpForm}
              setAccountCreated={setAccountCreated}
            />
          ) : (
            // Sign Up Form
            <Stack spacing={4}>
              <FormControl
                id="email"
                isRequired
                isInvalid={formErrorMessages.email}
              >
                <FormLabel>
                  {t("email")}
                  {/* <Tooltip
                      label={t("email_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip> */}
                </FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={signUpForm.email}
                  onChange={handleInputChange}
                  onBlur={async (event) => await handleInputValidation(event)}
                  placeholder={t("email_placeholder")}
                  autoComplete="email"
                />
                <FormErrorMessage>{formErrorMessages.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                id="phone_number"
                isRequired
                isInvalid={formErrorMessages.phone_number}
              >
                <FormLabel>
                  {t("phone_number")}
                  <Tooltip
                    label={t("phone_number_tip")}
                    bg="Neutral.300"
                    color="Neutral.0"
                    fontSize="sm"
                    borderRadius="md"
                    px={4}
                    py={3}
                  >
                    <InfoIcon
                      ml={2}
                      mr={1}
                      mb={1}
                      color="Neutral.300"
                      _hover={{ color: "Neutral.500" }}
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  type="tel"
                  name="phone_number"
                  value={signUpForm.phone_number}
                  onChange={handleInputChange}
                  onBlur={async (event) => await handleInputValidation(event)}
                  placeholder={t("phone_number_placeholder")}
                />
                <FormErrorMessage>
                  {formErrorMessages.phone_number}
                </FormErrorMessage>
              </FormControl>

              <HStack>
                <Box>
                  <FormControl
                    id="first_name"
                    isRequired
                    isInvalid={formErrorMessages.first_name}
                  >
                    <FormLabel>
                      {t("first_name")}
                      {/* <Tooltip
                          label={t("first_name_tip")}
                          bg="Neutral.300"
                          color="Neutral.0"
                          fontSize="sm"
                          borderRadius="md"
                          px={4}
                          py={3}
                        >
                          <InfoIcon
                            ml={2}
                            mr={1}
                            mb={1}
                            color="Neutral.300"
                            _hover={{ color: "Neutral.500" }}
                          />
                        </Tooltip> */}
                    </FormLabel>
                    <Input
                      type="text"
                      name="first_name"
                      value={signUpForm.first_name}
                      onChange={handleInputChange}
                      onBlur={async (event) =>
                        await handleInputValidation(event)
                      }
                      placeholder={t("first_name_placeholder")}
                    />
                    <FormErrorMessage>
                      {formErrorMessages.first_name}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl
                    id="last_name"
                    isRequired
                    isInvalid={formErrorMessages.last_name}
                  >
                    <FormLabel>
                      {t("last_name")}
                      {/* <Tooltip
                          label={t("last_name_tip")}
                          bg="Neutral.300"
                          color="Neutral.0"
                          fontSize="sm"
                          borderRadius="md"
                          px={4}
                          py={3}
                        >
                          <InfoIcon
                            ml={2}
                            mr={1}
                            mb={1}
                            color="Neutral.300"
                            _hover={{ color: "Neutral.500" }}
                          />
                        </Tooltip> */}
                    </FormLabel>
                    <Input
                      type="text"
                      name="last_name"
                      value={signUpForm.last_name}
                      onChange={handleInputChange}
                      onBlur={async (event) =>
                        await handleInputValidation(event)
                      }
                      placeholder={t("last_name_placeholder")}
                    />
                    <FormErrorMessage>
                      {formErrorMessages.last_name}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </HStack>

              <FormControl
                id="username"
                isRequired
                isInvalid={formErrorMessages.username}
              >
                <FormLabel>
                  {t("username")}
                  <Tooltip
                    label={t("username_tip")}
                    bg="Neutral.300"
                    color="Neutral.0"
                    fontSize="sm"
                    borderRadius="md"
                    px={4}
                    py={3}
                  >
                    <InfoIcon
                      ml={2}
                      mr={1}
                      mb={1}
                      color="Neutral.300"
                      _hover={{ color: "Neutral.500" }}
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  type="text"
                  name="username"
                  value={signUpForm.username}
                  onChange={handleInputChange}
                  onBlur={async (event) => await handleInputValidation(event)}
                  placeholder={t("username_placeholder")}
                  autoComplete="off"
                />
                <FormErrorMessage>
                  {formErrorMessages.username}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="password"
                isRequired
                isInvalid={formErrorMessages.password}
              >
                <FormLabel>
                  {t("password")}
                  <Tooltip
                    label={t("password_tip")}
                    bg="Neutral.300"
                    color="Neutral.0"
                    fontSize="sm"
                    borderRadius="md"
                    px={4}
                    py={3}
                  >
                    <InfoIcon
                      ml={2}
                      mr={1}
                      mb={1}
                      color="Neutral.300"
                      _hover={{ color: "Neutral.500" }}
                    />
                  </Tooltip>
                </FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={signUpForm.password}
                    onChange={handleInputChange}
                    onBlur={async (event) => await handleInputValidation(event)}
                    placeholder={t("password_placeholder")}
                    autoComplete="new-password"
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant="ghost"
                      size="md"
                      boxShadow="none"
                      _hover={{ bg: "Neutral.50", color: "Secondary.700" }}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {formErrorMessages.password}
                </FormErrorMessage>
              </FormControl>

              {/* TODO: Enable profile image field */}
              {/* <FormControl
                  id="user_image"
                  isRequired
                  isInvalid={formErrorMessages.user_image}
                >
                  <FormLabel>
                    {t("user_image")}
                    <Tooltip
                      label={t("user_image_tip")}
                      bg="Neutral.300"
                      color="Neutral.0"
                      fontSize="sm"
                      borderRadius="md"
                      px={4}
                      py={3}
                    >
                      <InfoIcon
                        ml={2}
                        mr={1}
                        mb={1}
                        color="Neutral.300"
                        _hover={{ color: "Neutral.500" }}
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    type="file"
                    name="user_image"
                    onChange={handleInputChange}
                    onBlur={async (event) => await handleInputValidation(event)}
                    mt={2}
                    p={1}
                  />
                  <FormErrorMessage>
                    {formErrorMessages.user_image}
                  </FormErrorMessage>
                </FormControl> */}

              {/* Submit */}
              <Stack spacing={10} pt={4}>
                <Button
                  variant="OrangePrimary"
                  onClick={async (event) => await handleSignUp(event)}
                >
                  {t("sign_up")}
                </Button>
              </Stack>

              <Stack pt={6}>
                <Text align="center" color="Neutral.800">
                  {t("already_have_account")}&nbsp;&nbsp;
                  <Link
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    fontWeight={500}
                    color="Secondary.700"
                  >
                    {t("sign_in")}
                  </Link>
                </Text>
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </Flex>
  );
}
