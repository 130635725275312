import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
  Stack,
  Link,
  Button,
  Text,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { RiErrorWarningFill } from "react-icons/ri";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import { useUserAuth } from "../context/UserAuthProvider";

export default function SignIn() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, i18n } = useTranslation("sign_in");
  const {
    meritoSignedIn,
    firebaseUserSignIn,
    firebasePasswordReset,
    meritoUserSignIn,
  } = useUserAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const checkSignInStatus = () => {
    // Move to the portal page if the user is signed in
    if (meritoSignedIn) {
      navigate("/portal");
    }
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    checkSignInStatus();

    // Sign in with email and password and store the user in the context
    const firebaseUser = await firebaseUserSignIn(email, password);
    const meritoUser = await meritoUserSignIn(firebaseUser);

    // move to the portal page
    if (meritoUser) navigate("/portal");
  };

  useEffect(() => {
    checkSignInStatus();
  }, [meritoSignedIn]);

  return (
    <Flex>
      <Stack
        className={i18n.language === "jp" ? "jp" : "en"}
        spacing={8}
        mx="auto"
        maxW="lg"
        pt={12}
        pb={16}
        px={6}
      >
        <Stack>
          <Text
            fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}
            textAlign="center"
            color="Neutral.100"
          >
            {t("title")}
          </Text>
        </Stack>

        {/* Sign In Form */}
        <Box borderRadius="lg" bg="Neutral.0" boxShadow="xl" p={9}>
          <Stack spacing={4}>
            <FormControl isRequired>
              {/* Hidden username field */}
              <Input
                type="text"
                name="username"
                id="username"
                style={{ display: "none" }}
                autoComplete="username"
              />
              <FormLabel>{t("email")}</FormLabel>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                value={email}
                type="email"
                autocomplete="email"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t("password")}</FormLabel>
              <InputGroup>
                <Input
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  value={password}
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                />
                <InputRightElement h="full">
                  <Button
                    variant="ghost"
                    size="md"
                    boxShadow="none"
                    _hover={{ bg: "Neutral.50", color: "Secondary.700" }}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {/* Password reset link and modal*/}
            <Stack
              direction={{ base: "column", sm: "row" }}
              justifyContent="start"
              alignItems="start"
            >
              <Link color="Neutral.600" onClick={onOpen}>
                {t("forgot_password")}
              </Link>
              <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay bg="blackAlpha.50" backdropFilter="blur(7px)" />
                <ModalContent boxShadow="2xl" mx={6}>
                  <Box bg="Neutral.0" borderRadius="xl" p={{ base: 5, md: 10 }}>
                    <ModalBody>
                      <Flex
                        direction="column"
                        alignItems="center"
                        color="Neutral.700"
                        fontSize={{ base: "md", md: "lg" }}
                        gap={5}
                        mt={2}
                      >
                        {/* Password reset description */}
                        <Icon
                          w={6}
                          h={6}
                          as={RiErrorWarningFill}
                          color="Neutral.500"
                          mr={3}
                        />
                        <Text fontWeight={400}>
                          {t("password_reset_description")}
                        </Text>

                        {/* Email input */}
                        <FormControl isRequired>
                          <FormLabel>{t("email")}</FormLabel>
                          <Input
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            value={email}
                            type="email"
                            autocomplete="email"
                          />
                        </FormControl>
                      </Flex>
                    </ModalBody>

                    {/* Buttons */}
                    <ModalFooter>
                      <Flex
                        w="full"
                        direction={{ base: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                        gap={6}
                      >
                        <Button
                          w={{ base: "140px", md: "160px" }}
                          bg="Neutral.100"
                          color="Neutral.600"
                          fontSize={{ base: "md", md: "lg" }}
                          fontWeight={400}
                          _hover={{
                            bg: "Neutral.100",
                            color: "Neutral.800",
                            boxShadow: "lg",
                          }}
                          onClick={onClose}
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          w={{ base: "140px", md: "160px" }}
                          bg="ScienceDeals.500"
                          color="Neutral.50"
                          fontSize={{ base: "md", md: "lg" }}
                          fontWeight={400}
                          _hover={{
                            bg: "ScienceDeals.600",
                            color: "Neutral.100",
                            boxShadow: "lg",
                          }}
                          onClick={async () => {
                            await firebasePasswordReset(email);
                          }}
                        >
                          {t("reset")}
                        </Button>
                      </Flex>
                    </ModalFooter>
                  </Box>
                </ModalContent>
              </Modal>
            </Stack>

            {/* Submit */}
            <Stack spacing={10} pt={6}>
              <Button
                isDisabled={!(email && password)}
                variant="OrangePrimary"
                onClick={async (event) => await handleSignIn(event)}
              >
                {t("sign_in")}
              </Button>

              <Stack>
                <Text align="center" color="Neutral.800">
                  {t("new_to_merito")}&nbsp;&nbsp;
                  <Link
                    onClick={() => {
                      navigate("/sign-up");
                    }}
                    fontWeight={500}
                    color="Secondary.700"
                  >
                    {t("sign_up")}
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
